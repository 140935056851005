.overlay .deleteArticle > summary {
  min-width: min-content;
  white-space: nowrap;
  background: #000;
}

.overlay .deleteArticle > div {
  width: max-content;
  background: #ff4500;
  outline: 1.5px double #000;
  flex-direction: column;
  padding: .5rem;
  display: flex;
}

.overlay .deleteArticle[open] > summary::marker {
  content: "cancel deletion: ";
}

.overlay .deleteArticle[open] {
  background: #000;
}

.overlay .deleteArticle button {
  max-width: 50%;
}

.overlay details {
  transform: translate(calc(var(--unit) / 2), calc(0px - var(--unit) / 2) );
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}

.overlay > button {
  width: var(--unit);
  height: var(--unit);
  margin: calc(0px - var(--unit) / 2);
  box-sizing: border-box;
  color: #fff;
  mix-blend-mode: screen;
  background: #00000003;
  border: .5px dashed #ff4500;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  transition: all .2s;
  display: flex;
}

.overlay > button > span {
  height: 2rem;
  width: 2rem;
  color: #ff4500;
  background: #00000003;
  border-radius: 1rem;
  font-size: 1.4rem;
  line-height: 1.9rem;
  display: block;
}

.overlay button:hover {
  cursor: cell;
  background: #000000e6;
  transform: scale(1.1);
}

.overlay button:hover > span {
  color: #000;
  background: #ff4500;
}

#activityLog {
  max-height: 100vh;
  opacity: .95;
  z-index: 10;
  background: #8e360a;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

#activityLog > ol {
  padding-bottom: 6rem;
}

#activityLog > ol > li > pre {
  font-size: .7em;
  line-height: 1em;
}

input[type="radio"]:focus + span, input[type="checkbox"]:focus + span {
  outline-offset: .5px;
  outline: 2px solid #0000ff80;
}

input[type="text"] {
  outline-offset: .5px;
  width: 100%;
  outline: 1px inset #ddd;
}

.distance-holder ~ .ui {
  width: auto;
}

legend:not(.no-break) > label {
  min-width: 50%;
}

button.ui:focus {
  outline-offset: .5px;
  outline: 2px solid #0000ff80;
}

.sheet {
  width: var(--column);
  color: #000;
  z-index: 9;
  outline-offset: .5px;
  background: #ff4500;
  border: 2px double #000;
  outline: 1rem solid #0000004d;
  margin-left: -50%;
  font-family: subarumedium;
  position: fixed;
  bottom: 0;
  left: 50vw;
}

.sheet .editCaption {
  display: flex;
}

.sheet .editCaption > input {
  flex-grow: 1;
}

.sheet .editCaption > fieldset {
  width: min-content;
}

.sheet fieldset {
  margin: 0 2px;
}

.sheet input.link {
  width: 100%;
  box-sizing: border-box;
}

.sheet .remove {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.sheet .add {
  width: 100%;
}

.sheet .add, .sheet .remove {
  color: #ff4500;
  background: #000;
  border: none;
}

.sheet .add:hover, .sheet .remove:hover {
  color: #fff;
}

.sheet ul.occasions {
  padding: 0;
  list-style-type: none;
}

.sheet ul.occasions .occasion {
  position: relative;
}

.sheet ul.occasions .occasion .edit label {
  display: block;
}

.sheet ul.occasions .occasion .edit label span {
  min-width: 3rem;
  display: inline-block;
}

.sheet .dates .remove {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.sheet .dates .add {
  width: 100%;
}

.sheet .dates .add, .sheet .dates .remove {
  color: #ff4500;
  background: #000;
  border: none;
}

.sheet .dates .add:hover, .sheet .dates .remove:hover {
  color: #fff;
}

.pick, .pickOrNot {
  box-sizing: border-box;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.pick > label, .pickOrNot > label {
  text-align: center;
  min-width: 2rem;
  transition: all .1s;
  position: relative;
}

.pick > label > span, .pickOrNot > label > span {
  border-radius: 1rem;
  padding: 0 .5rem;
  font-weight: bold;
  transition: all .2s;
  display: block;
}

.pick > label > input, .pickOrNot > label > input {
  width: 1rem;
  height: 1rem;
  opacity: 0;
  border: 0;
  margin: -.5px;
  padding: 0;
  position: absolute;
}

.pick > label > input:not([disabled]), .pick > label > input:not([disabled]) ~ span, .pickOrNot > label > input:not([disabled]), .pickOrNot > label > input:not([disabled]) ~ span {
  cursor: pointer;
}

.pick > label > input:checked ~ span, .pickOrNot > label > input:checked ~ span {
  background: var(--active-background);
  color: var(--active-foreground);
}

.pick > label > input:not(:checked) ~ span, .pickOrNot > label > input:not(:checked) ~ span {
  background: var(--background);
  color: var(--foreground);
}

.pick > label > input[disabled] ~ span, .pickOrNot > label > input[disabled] ~ span {
  color: gray;
  text-shadow: 1px 1px 1px #fff;
  outline-offset: -.5px;
  background: #ccc;
  outline: .5px outset #ddd;
}

.pick > label > input[disabled]:checked ~ span, .pickOrNot > label > input[disabled]:checked ~ span {
  color: #777;
  outline-offset: -.5px;
  text-shadow: 1px 1px 1px #eee;
  background: #bbb;
  outline: .5px inset #ddd;
}

.pick > label:hover > input:not(:checked) ~ span, .pickOrNot > label:hover > input:not(:checked) ~ span {
  outline: 1px dashed var(--active-background);
  outline-offset: -1px;
}

.deleteArticle > summary::marker {
  content: "...";
}

.deleteArticle[open] > summary::marker {
  content: "Cancel";
}

summary, button:enabled, a:link[href] {
  cursor: pointer;
}

summary:disabled, button:disabled, a:link:not[href] {
  cursor: not-allowed;
}

.fill-h {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.stretch-h {
  width: 100%;
  display: flex;
}

.no-break {
  display: flex;
}

.no-break > * {
  width: max-content;
}

.ui {
  --background: #ccc;
  --bevel: #ddd;
  --active-background: #075;
  --active-bevel: #186;
  --active-foreground: #efc;
  --mode-background: #bbb;
  --mode-bevel: #ccc;
  --mode-active-background: #999;
  --mode-active-bevel: #aaa;
  --foreground: black;
}

.ui, .ui input {
  font-family: subarumedium;
  font-size: .8rem;
}

button.ui {
  box-sizing: border-box;
  min-height: 2rem;
  background: none;
  border: 2px solid #0000;
}

button.ui:enabled:hover {
  border: 2px groove var(--bevel);
}

button.ui:enabled:active:hover {
  border: 2px inset var(--active-bevel);
  background: var(--active-background);
}

button.ui.square {
  width: 2rem;
  flex-grow: 0;
}

button.ui.stretching {
  flex-grow: 1;
}

button.ui[aria-checked="true"] {
  border: 2px groove var(--active-bevel);
  background: var(--active-background);
  color: var(--active-foreground);
}

button.ui.mode[aria-checked="true"] {
  border: 2px solid var(--background);
  background: var(--mode-background);
  color: var(--foreground);
}

button.ui.mode[aria-checked="true"]:enabled:hover {
  border: 2px groove var(--mode-bevel);
}

button.ui.mode:active, button.ui.mode[aria-checked="true"]:enabled:active {
  background: var(--mode-active-background);
  border: 2px inset var(--mode-active-bevel);
}

.sheet.ui {
  border-bottom-width: 0;
}

.sheet.ui:not(:hover) {
  opacity: 1;
}

.control.ui {
  border-top-width: 0;
}

.flying.ui, .control.ui, .sheet.ui {
  background: var(--background);
  border: 2px outset var(--bevel);
  color: #000;
}

.sheet.ui {
  margin-left: calc(0px - var(--column) / 2);
}

fieldset.ui {
  border: 2px groove var(--bevel);
  padding-top: 0;
}

fieldset.ui > legend {
  margin-bottom: -.5rem;
}

.sheet.ui > fieldset {
  border-right: 2px groove var(--bevel);
  border-left: 2px groove #fff;
}

button.ui:first-child {
  border-left-color: #0000;
  margin-left: -2px;
}

button.ui:last-child {
  border-right-color: #0000;
  margin-right: -2px;
}

button.ui:first-child:hover {
  border-left-color: #0000;
}

button.ui:last-child:hover {
  border-right-color: #0000;
}

.ui input[type="checkbox"] {
  position: relative;
  top: 2px;
}

label.ui {
  padding: .3rem 0;
  display: block;
}

label.ui > input[type="text"] {
  height: fit-content;
  background: #ffffff1a;
  border: .5px dotted #fff;
  margin: 0;
  padding: 0;
  transition: all .2s;
}

label.ui > input[type="text"]:hover {
  background: #fff;
  margin: -.3rem 0;
  padding: .3rem 0;
}

.flying {
  width: max-content;
  z-index: 11;
  outline: 1.5px double #000;
  position: absolute;
}

.flying.right-aligned {
  right: -1rem;
}

.flying.bottom-aligned {
  bottom: 2rem;
}

.fly-orientation {
  text-align: center;
  min-width: 2rem;
  position: relative;
}

.fly-orientation > summary:hover {
  background: var(--mode-active-bevel);
}

.fly-orientation[open] > summary:not(:hover) {
  background: var(--mode-active-background);
  outline: 2px inset var(--mode-active-bevel);
}

.distance-holder {
  height: 0;
  border-top: 2px groove var(--bevel);
  flex-grow: 1;
  margin: auto 0;
}

.sheet.ui {
  transition: width .4s, margin-left .4s, opacity .2s;
}

#activityLog:not(:hover) {
  opacity: .8;
}

.row, .column {
  justify-content: space-between;
  display: flex;
}

/*# sourceMappingURL=index.becf16b8.css.map */
