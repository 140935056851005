.overlay {
    .deleteArticle>summary {
        background: black;
        min-width: min-content;
        white-space: nowrap;
    }

    .deleteArticle>div {
        display: flex;
        flex-direction: column;
        background: orangered;
        width: max-content;
        padding: 0.5rem;
        outline: 1.5px double black;
    }

    .deleteArticle[open]>summary::marker {
        content: "cancel deletion: ";
    }

    .deleteArticle[open] {
        background: black;
    }

    .deleteArticle button {
        max-width: 50%;
    }

    details {
        transform: translate(calc(var(--unit) / 2), calc(0px - var(--unit) / 2));
        position: absolute;
        right: 0;
        bottom: 0rem;
        text-align: right;
    }

    >button {
        width: var(--unit);
        height: var(--unit);
        border-radius: 50%;
        margin: calc(0px - var(--unit) / 2);
        border: 0.5px dashed orangered;
        background: rgba(0, 0, 0, .01);
        box-sizing: border-box;
        font-size: 1rem;
        color: white;
        transition: all .2s;
        mix-blend-mode: screen;
        display: flex;
        align-items: center;
        justify-content: center;


        >span {
            height: 2rem;
            width: 2rem;
            line-height: 1.9rem;
            font-size: 1.4rem;
            border-radius: 1rem;
            display: block;
            background: rgba(0, 0, 0, .01);
            color: orangered;
        }
    }

    button:hover {
        transform: scale(1.1);
        background: rgba(0, 0, 0, .9);
        cursor: cell;

        >span {
            background: orangered;
            color: black;
        }
    }
}

#activityLog {
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100vh;
    overflow: scroll;
    background: rgb(142, 54, 10);
    opacity: 0.95;
    z-index: 10;

    >ol {
        padding-bottom: 6rem;


        >li {


            >pre {
                font-size: 0.7em;
                line-height: 1em;
            }
        }
    }

}

input[type="radio"]:focus+span {
    outline: 2px solid rgba(0, 0, 255, 0.5);
    outline-offset: 0.5px;
}

input[type="checkbox"]:focus+span {
    outline: 2px solid rgba(0, 0, 255, 0.5);
    outline-offset: 0.5px;
}
input[type="text"] {
    outline: 1px inset #ddd;
    outline-offset: 0.5px;
    width:100%;
}
.distance-holder ~ .ui {
    width:auto;
}

legend:not(.no-break) > label {
    min-width:50%;
}

button.ui:focus {
    outline: 2px solid rgba(0, 0, 255, 0.5);
    outline-offset: 0.5px;
}

.sheet {

    .editCaption {
        display: flex;

        >input {
            flex-grow: 1;
        }

        >fieldset {
            width: min-content;
        }
    }

    fieldset {
        margin: 0 2px;
    }

    input.link {
        width: 100%;
        box-sizing: border-box;
    }

    font-family: 'subarumedium';
    position: fixed;
    width: var(--column);
    left: 50vw;
    bottom: 0;
    margin-left: calc(0px - 50%);
    background: orangered;
    color: black;
    border: 2px double black;
    z-index:9;
    outline: 1rem solid rgba(0, 0, 0, .3);
    outline-offset: 0.5px;


    .remove {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }

    .add {
        width: 100%;
    }

    .add,
    .remove {
        background: black;
        color: orangered;
        border: none;

        &:hover {
            color: white;
        }
    }

    ul.occasions {
        list-style-type: none;
        padding: 0;

        .occasion {
            position: relative;



            .edit {
                label {
                    display: block;

                    span {
                        display: inline-block;
                        min-width: 3rem;
                    }
                }
            }
        }
    }

    .dates {


        .remove {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
        }

        .add {
            width: 100%;
        }

        .add,
        .remove {
            background: black;
            color: orangered;
            border: none;

            &:hover {
                color: white;
            }
        }
    }



}

.pick,
.pickOrNot {
    display: flex;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    border: none;
    position: relative;

    // justify-content: center;

    >label {
        position: relative;
        text-align: center;
        min-width: 2rem;

        >span {
            font-weight: bold;
            display: block;
            transition: all .2s;
            border-radius: 1rem;
            padding: 0 .5rem;
        }

        >input {
            width: 1rem;
            height: 1rem;
            margin: -0.5;
            opacity: 0;
            padding: 0;
            border: 0;
            position: absolute;
        }

        >input:not([disabled]), >input:not([disabled])~span {
            cursor: pointer;
        }

        >input:checked~span {
            background: var(--active-background);
            color: var(--active-foreground);
        }

        >input:not(:checked)~span {
            background: var(--background);
            color: var(--foreground);
        }

        > input[disabled]~span {
            background:#ccc;
            color:grey;
            text-shadow: 1px 1px 1px white;
            outline:0.5px outset #ddd;
            outline-offset:-0.5px;
        }

        > input[disabled]:checked~span {
            background:#bbb;
            color:#777;
            outline:0.5px inset #ddd;
            outline-offset:-0.5px;
            text-shadow: 1px 1px 1px #eee;
        }

        transition:all .1s;

        &:hover>input:not(:checked)~span {
            outline: 1px dashed var(--active-background);
            outline-offset: -1px;
        }
    }
}

.deleteArticle>summary::marker {
    content: "...";
}

.deleteArticle[open]>summary::marker {
    content: "Cancel";
}


// defaults

summary,
button:enabled,
a:link[href] {
    cursor: pointer;
}

summary:disabled,
button:disabled,
a:link:not[href] {
    cursor: not-allowed;
}


// utilities

.fill-h {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.stretch-h {
    width: 100%;
    display: flex;
}

.no-break {
    >* {
        width: max-content
    }

    display:flex;
}

.ui {
    --background: #CCC;
    --bevel: #DDD;
    --active-background: #075;
    --active-bevel: #186;
    --active-foreground: #efc;
    --mode-background: #BBB;
    --mode-bevel: #CCC;
    --mode-active-background: #999;
    --mode-active-bevel: #aaa;
    --foreground: black;
}

.ui,
.ui input {
    font-family: 'subarumedium';
    font-size: 0.8rem;
}

button.ui {
    border: 2px solid transparent;
    background: transparent;
    box-sizing: border-box;
    min-height: 2rem;
}


button.ui:enabled:hover {
    border: 2px groove var(--bevel);
}

button.ui:enabled:active:hover {
    border: 2px inset var(--active-bevel);
    background: var(--active-background);
}

button.ui.square {
    width: 2rem;
    flex-grow: 0;
}

button.ui.stretching {
    flex-grow: 1;
}

button.ui[aria-checked="true"] {
    border: 2px groove var(--active-bevel);
    background: var(--active-background);
    color: var(--active-foreground);
}

button.ui.mode[aria-checked="true"] {
    border: 2px solid var(--background);
    background: var(--mode-background);
    color: var(--foreground);
}

button.ui.mode[aria-checked="true"]:enabled:hover {
    border: 2px groove var(--mode-bevel);
}


button.ui.mode:active {
    background: var(--mode-active-background);
    border: 2px inset var(--mode-active-bevel);

}

button.ui.mode[aria-checked="true"]:enabled:active {
    background: var(--mode-active-background);
    border: 2px inset var(--mode-active-bevel);

}

.sheet.ui {
    border-bottom-width: 0;

    &:not(:hover) {
        opacity: 1;
    }
}

.control.ui {

    border-top-width: 0;
}

.flying.ui,
.control.ui,
.sheet.ui {
    background: var(--background);
    border: 2px outset var(--bevel);
    color: black;
}


.sheet.ui {
    margin-left: calc(0px - var(--column) / 2);
}

fieldset.ui {
    padding-top: 0;
    border: 2px groove var(--bevel);

    >legend {
        margin-bottom: -0.5rem;
    }
}

.sheet.ui>fieldset {
    border-right: 2px groove var(--bevel);
    border-left: 2px groove white;

}

button.ui:first-child {
    margin-left: -2px;
    border-left-color: transparent;
}

button.ui:last-child {
    margin-right: -2px;
    border-right-color: transparent;
}

button.ui:first-child:hover {
    border-left-color: transparent;
}

button.ui:last-child:hover {
    border-right-color: transparent;
}

.ui input[type="checkbox"] {
    position: relative;
    top: 2px;
}

label.ui {
    padding: 0.3rem 0;
    display: block;

    >input[type="text"] {
        background: rgba(255, 255, 255, .1);
        border: none;
        border: 0.5px dotted white;
        height: fit-content;
        transition: all .2s;
        padding: 0;
        margin: 0;

        &:hover {
            background: white;
            margin: 0;
            padding: 0.3rem 0;
            margin: -0.3rem 0;
        }
    }
}

.flying {
    position: absolute;

    width: max-content;
    z-index: 11;
    outline: 1.5px double black;

    &.right-aligned {
        right: -1rem;
    }

    &.bottom-aligned {
        bottom: 2rem;
    }
}

.fly-orientation {
    position: relative;
    text-align: center;
    min-width: 2rem;
}

.fly-orientation>summary:hover {
    background: var(--mode-active-bevel);
}

.fly-orientation[open]>summary:not(:hover) {
    background: var(--mode-active-background);
    outline: 2px inset var(--mode-active-bevel);
}

.distance-holder {
    margin: auto 0;
    height: 0px;
    flex-grow: 1;
    border-top: 2px groove var(--bevel)
}


.sheet.ui {

    transition: width 0.4s,
    margin-left 0.4s,
    opacity 0.2s;
}

#activityLog {
    &:not(:hover) {
        opacity: 0.8;
    }
}

.row,
.column {
  display: flex;
  justify-content: space-between;
}